<template>
  <div>
    Tìm vé tàu
  </div>
</template>

<script>
import { ref, computed, onUnmounted } from '@vue/composition-api'
import { BCard, BCardBody, BButton } from 'bootstrap-vue'

import store from '@/store'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BCard,
    BButton,
  },
  setup() {
    // const { loading } = useTrainHandle()
    // if (!store.hasModule('app-train')) {
    //   store.registerModule('app-train', storeModule)
    // }

    // resetStore()

    // onUnmounted(() => {
    //   if (store.hasModule('app-train')) {
    //     store.unregisterModule('app-train')
    //   }
    // })

    return {
    }
  },
}
</script>
